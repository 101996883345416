import React, { Component } from 'react'
import logo from './ch-logo.png';
import AppPhoto from './app-photo.png'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

export default class App extends Component {
  render() {
    return (
      <div className="w-screen h-screen overflow-hidden bg-[#13073b]">
        <header className='text-center shadow-2xl p-2 '>
          <img className='object-contain w-full h-[60px]  md:h-[80px]  flex justify-center items-center' src={logo}></img>

        </header>
        <div className=' w-full h-full flex md:flex-row flex-col mt-5 md:mt-10 justify-center items-center'>
          <div className='w-full h-1/2 md:h-full md:w-1/2 flex flex-col  items-center px-3 '>

            <div className='text-lg font-semibold md:text-4xl mt-0 md:mt-10 text-white' style={{ fontFamily: "bitter-medium", }}>OYNAMAK İSTEDİĞİN SİTELER ARTIK</div>
            <div className='text-3xl font-semibold md:text-7xl  text-white underline' style={{ fontFamily: "bitter-extrabold", }}>TEK EKRANDA</div>


            <div className='text-xl font-semibold md:text-3xl mt-10 text-white' style={{ fontFamily: "bitter-medium", }}>HEMEN İNDİR</div>




            <Link to="https://i.diawi.com/xQD1Rr" className='bg-[#ffbc00] p-2 px-10 rounded-lg font-bold mt-5'>

              İndirmek için TIKLA! (.apk)
            </Link>



          </div>
          <div className='w-[90%] h-[70%] md:h-full md:w-1/2 '>
            <img className='w-full h-full object-contain' src={AppPhoto}></img>
          </div>
        </div>
      </div>


    )
  }
}
